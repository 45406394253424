<template>
  <div class="dptBonus0">
    <div v-title
         data-title="电竞存送"></div>
    <!-- 
    <van-nav-bar
      title="电竞存送"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <!-- <img :src="`${imgUrl}pcbb/bg-DptBonus.png`" /> -->
      <img :src="`${imgUrl}pcbb/bg-Esportssend.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"><img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>活动平台:</p>
              <span style="color:var(--li-colortext);">贝宝电竞、IM电竞</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">长期</span>
            </div>
            <div class="title-item">
              <p>活动要求:</p>
              <span style="color:var(--li-colortext);">完善资料</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td style="
                        color: #fff;
                        border-top-left-radius: 10px;
                        border-right: 1px solid #e2d2bf;
                      ">
                  项目
                </td>
                <td style="color: #fff; border-top-right-radius: 10px">条件</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>存款金额</td>
                <td>{{ datalist.dpt_money }} 以上</td>
              </tr>
              <tr>
                <td>红利比例</td>
                <td>{{ datalist.bonus_rate }}%</td>
              </tr>
              <tr>
                <td>最高奖金</td>
                <td>{{ datalist.max_bonus }}</td>
              </tr>
              <tr>
                <td>流水限制</td>
                <td>{{ datalist.beat_limit }} 倍</td>
              </tr>
              <tr>
                <td>次数限制</td>
                <td>每个账号仅限{{ datalist.times_limit || 1 }}次</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box"
               @click="alllyWay">立即申请</div>

          <div style="
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #e93f3f;
                  font-size: 12px;
                  text-align: center;
                  padding-bottom: 15px;
                ">
            备注:参加此活动会员，流水仅计算电竞游戏有效投注。
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                指定场馆：贝宝电竞、IM电竞
              </li>
              <li>
                <span>2.</span>
                每位新用户可申请1次{{
                  datalist.bonus_rate
                }}％奖金，有效投注额达到【（本金+红利）x8倍流水】即可提款
              </li>
              <li>
                <span>3.</span>
                存款后当天申请才能生效。
              </li>
              <li>
                <span>4.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息；并且存款后请立即提交申请，不要进入任何游戏场馆投注后再提交申请则视为无效。
              </li>
              <li>
                <span>5.</span>
                该优惠活动成功申请后不能取消，必须打满流水才能提款。
              </li>
              <li>
                <span>6.</span>
                体育存送、真人存送、电竞存送、棋牌存送等活动不能同时申请。
              </li>
              <li>
                <span>7.</span>
                任何虚拟电竞/金融投注/彩票投注将不被计算在有效投注内。
              </li>

              <li>
                <span>8.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>9.</span>
                有效投注额仅对已结算并产生输赢结果的*注单*进行计算，任何走盘，取消的赛事将不计算在有效投注。（例如：下注100元，香港盘赔率0.2，如赢派彩20元，有效投注为20元；如输本金100元，有效投注为100元。）
              </li>
              <li>
                <span>10.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>11.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>

              <li>
                <span>12.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker title="请选择场馆"
                  show-toolbar
                  value-key="name"
                  :columns="datalist.youxi"
                  @confirm="confirm"
                  @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: [],
      isscrollTop: 'transparent',
      showPicker: false,
      cueTheme: ''

    }
  },
  mounted() {
    this.cueTheme = window.localStorage.getItem('cueTheme')
    this.getdata()
  },

  methods: {
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },
    getdata() {
      var _this = this
      var data = '?type=ImeDptBonus'
      getdata(data).then((res) => {
        this.datalist = res.message
      })
    },
    alllyWay() {
      this.showPicker = true
      // uni.getEnv((res) => {
      //   if (Object.keys(res)[0] === 'h5') {
      //     Toast('该活动仅在app开发,请下载APP')
      //   } else {

      //   }
      // })
    },
    confirm(e) {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'DdptBonus',
                  gameid: e.id
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'DdptBonus',
          //       gameid: e.id
          //     },
          //   }
          // })
          uni.postMessage({
            data: {
              urlApply: 'vipActapply',
              actname: 'DdptBonus',
              gameid: e.id
            }
          })
        }
      })

      this.showPicker = false
    },
    cancel() {
      this.showPicker = false
    }
  }
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000 !important;
}

body {
  background-color: #f7f8fa;
}

.dptBonus0 {
  font-family: PingFang SC;
  position: absolute;

  .activityRules {
    margin: 10px 15px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 15px 20px 15px;
    padding-bottom: 5px;
    position: relative;
    z-index: 0;

    .title {
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    ul li {
      color: #000;
      margin-bottom: 16px;
      position: relative;
      padding-left: 16px;
      font-size: 14px;
      line-height: 1.5em;

      > span {
        text-align: center;
        position: absolute;
        top: 0px;
        left: 0;
      }
    }
  }

  .btn-box {
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    margin: 25px 0px 15px 0px;
  }
}
</style>
